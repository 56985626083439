.dot-1,
.dot-2,
.dot-3 {
  animation: bounce 1s infinite alternate;
  opacity: 0;
  display: inline-block;
}

.dot-1 {
  animation-delay: 0.2s;
}

.dot-2 {
  animation-delay: 0.4s;
}

.dot-3 {
  animation-delay: 0.6s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(-10px);
    opacity: 1;
  }
}
