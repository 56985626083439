.select-user-type {
    .ant-select-selector {
      background-color: white !important;
      border: white !important;
      width: 100%;
      cursor: pointer !important;
    }

    input {
        cursor: pointer !important;
    }
  
    .ant-select-selection-item {
      font-size: smaller !important;
    }
  
    .ant-select-selection-overflow {
      justify-content: center !important;
      gap: 24px;
    }
  
    .ant-select-focused .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: #d9d9d9 !important;
      box-shadow: none !important;
      caret-color: transparent;
    }
  
    .ant-select-open {
      .ant-select-arrow {
        display: none;
      }
    }

  }